export class UpdateRecommendationDto {
  constructor(recommendation) {
    this.analystId = recommendation.analystId ? recommendation.analystId : null;
    this.ticker = recommendation.ticker;
    this.priceTarget = recommendation.priceTarget;
    this.recommendation = recommendation.recommendation.toLowerCase();
    this.date = recommendation.date;
    this.expiredAt = recommendation.expiredAt;

    this.article = typeof recommendation.article === "object" ? recommendation.article.url : recommendation.article;
  }
}
