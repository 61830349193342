<template>
  <div>
    <h2 class="mb-4">Рекомендации</h2>

    <recommendations-filters :table-params="tableParams" @change-field="changeField" />

    <data-table
      ref="dataTable"
      url="stock-ratings"
      sort-by="date"
      :sort-desc="true"
      :table-params="tableParams"
      :headers="headers"
    >
      <template v-slot:[`item.expertName`]="{ item: recommendation }">
        {{ recommendation.expertName }}
      </template>

      <template v-slot:[`item.expertFirm`]="{ item: recommendation }">
        {{ recommendation.expertFirm }}
      </template>

      <template v-slot:[`item.ticker`]="{ item: recommendation }">
        {{ recommendation.ticker }}
      </template>

      <template v-slot:[`item.recommendation`]="{ item: recommendation }">
        {{ recommendation.recommendation | consensus }}
      </template>

      <template v-slot:[`item.priceTarget`]="{ item: recommendation }">
        {{ recommendation.priceTarget | number }}
      </template>

      <template v-slot:[`item.date`]="{ item: recommendation }">
        {{ recommendation.date | date }}
      </template>

      <template v-slot:[`item.expiredAt`]="{ item: recommendation }">
        {{ recommendation.expiredAt | date }}
      </template>

      <template v-slot:[`item.article`]="{ item: recommendation }">
        <a v-if="getUrlArticle(recommendation.article)" :href="getUrlArticle(recommendation.article)" target="_blank">
          Статья
        </a>

        <span v-else>—</span>
      </template>

      <template v-slot:[`item.actions`]="{ item: recommendation }">
        <div class="d-flex align-center">
          <v-btn class="ml-3" icon color="accent" @click="openEditModal(recommendation)">
            <v-icon small>mdi-pencil</v-icon>
          </v-btn>

          <v-btn class="ml-3" icon color="error" @click="confirmDelete(recommendation)">
            <v-icon small>mdi-delete</v-icon>
          </v-btn>
        </div>
      </template>
    </data-table>

    <stock-forecasts-confirm-delete
      :is-open="paramsConfirm.isOpen"
      subtitle="Вы уверены, что хотите удалить рекомендацию?"
      @close="paramsConfirm.isOpen = false"
      @delete="deleteRecommendation(paramsConfirm.id)"
    />

    <recommendations-edit
      v-if="activeRecommendation !== null"
      :recommendation="activeRecommendation"
      @change-field="changeRecommendationField"
      @close="resetRecommendationFields"
      @update="updateRecommendation"
    />
  </div>
</template>

<script>
import { ApiValidationError } from "~/core/api-validation-error";
import { UpdateRecommendationDto } from "~/libs/recommendation/update-recommendation-dto";

import { editorForm } from "~/mixins/multi-editor/editor-form";

import StockForecastsConfirmDelete from "~/components/shared/stock-forecasts/confirm-delete";

import RecommendationsFilters from "~/components/recommendations/index/filters";
import RecommendationsEdit from "~/components/recommendations/index/edit";
import { store } from "~/store";

export default {
  mixins: [editorForm],
  data() {
    return {
      headers: [
        { text: "Аналитик", value: "expertName", sortable: true },
        { text: "Компания", value: "expertFirm", sortable: true },
        { text: "Тикер", value: "ticker", sortable: true },
        { text: "Рекомендация", value: "recommendation", sortable: true },
        { text: "Прог. цена", value: "priceTarget", sortable: true },
        { text: "Дата прогноза", value: "date", sortable: true },
        { text: "Прогноз до", value: "expiredAt", sortable: true },
        { text: "Статья", value: "article", sortable: false },
        { text: "Действия", value: "actions", sortable: false }
      ],
      tableParams: {},
      paramsConfirm: {
        id: null,
        isOpen: false
      },

      activeRecommendation: null
    };
  },
  async mounted() {
    await this.$store.dispatch("forms/fetchExpertsByType", { expertType: "analyst" });
  },
  methods: {
    refreshTable() {
      return this.$refs.dataTable.update();
    },
    async deleteRecommendation(id) {
      try {
        await this.$axios.$delete(`stock-ratings/${id}`);

        await this.refreshTable();

        this.$snackbar.success("Удалено");

        this.paramsConfirm.isOpen = false;
      } catch (e) {
        const html = new ApiValidationError(e).toHtml();

        this.$snackbar.error("Не удалось удалить: " + html);
      }
    },
    async updateRecommendation() {
      try {
        await this.$axios.$put(
          `stock-ratings/${this.activeRecommendation.id}`,
          new UpdateRecommendationDto(this.activeRecommendation)
        );

        await this.refreshTable();

        this.$snackbar.success("Рекомендация изменена");

        this.activeRecommendation = null;
      } catch (e) {
        const html = new ApiValidationError(e, Object.keys(this.activeRecommendation)).toHtml();

        this.$snackbar.error("Не удалось изменить рекомендацию: " + html);
      }
    },
    confirmDelete(recommendation) {
      this.paramsConfirm.id = recommendation.id;

      this.paramsConfirm.isOpen = true;
    },

    getUrlArticle(recommendation) {
      return recommendation?.url;
    },
    changeField(payload) {
      this.commitChange(this.tableParams, payload);
    },
    openEditModal(recommendation) {
      this.activeRecommendation = { ...recommendation, analystId: recommendation.expertId };
    },
    changeRecommendationField({ field, value }) {
      this.activeRecommendation[field] = value;
    },
    resetRecommendationFields() {
      this.activeRecommendation = null;
    }
  },
  components: {
    StockForecastsConfirmDelete,
    RecommendationsFilters,
    RecommendationsEdit
  }
};
</script>
~/libs/recommendation/mistakes/update-recommendation-dto
