<template>
  <v-dialog :value="true" max-width="500px" @click:outside="$emit('close')">
    <v-card>
      <v-card-title>
        <span class="text-h6">Редактирование рекомендации</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="6">
              <experts-select v-model="analystId" label="Аналитик" />
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field :value="recommendation.ticker" label="Тикер" @input="setField('ticker', $event)" />
            </v-col>

            <v-col cols="12" sm="6">
              <v-select
                :value="recommendation.recommendation.toLowerCase()"
                :items="recommendationsTypes"
                label="Рекомендация"
                item-value="uid"
                item-text="text"
                required
                @input="setField('recommendation', $event)"
              />
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field v-model="priceTarget" label="Прог. цена" />
            </v-col>

            <v-col cols="12" sm="6">
              <date-picker :value="recommendation.date" label="Дата прогноза" @input="setField('date', $event)" />
            </v-col>

            <v-col cols="12" sm="6">
              <date-picker
                :value="recommendation.expiredAt"
                label="Прогноз до"
                @input="setField('expiredAt', $event)"
              />
            </v-col>

            <v-col cols="12">
              <v-text-field v-model="articleUrl" label="Статья" />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn color="blue darken-1" text @click="$emit('close')">Отмена</v-btn>
        <v-btn color="blue darken-1" text @click="$emit('update')">Обновить</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { editorFields } from "~/mixins/multi-editor/editor-fields";

import ExpertsSelect from "~/components/shared/experts/select";

export default {
  mixins: [editorFields],
  props: {
    recommendation: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      recommendationsTypes: [
        { uid: "buy", text: "Покупать" },
        { uid: "hold", text: "Держать" },
        { uid: "sell", text: "Продавать" }
      ]
    };
  },
  computed: {
    priceTarget: {
      get() {
        return this.recommendation.priceTarget;
      },
      set(value) {
        this.setField("priceTarget", value.replace(",", "."));
      }
    },
    articleUrl: {
      get() {
        return this.recommendation?.article?.url ?? "";
      },
      set(value) {
        return this.setField("article", value);
      }
    },
    analystId: {
      get() {
        return this.recommendation?.analystId ?? "";
      },
      set(value) {
        return this.setField("analystId", value);
      }
    }
  },
  components: {
    ExpertsSelect
  }
};
</script>
