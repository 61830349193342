<template>
  <v-sheet elevation="3" class="pa-3">
    <v-row>
      <v-col cols="12" sm="6" md="3">
        <v-text-field
          label="Поиск по тикеру"
          :value="tableParams.ticker"
          clearable
          @input="setField('ticker', $event)"
        />
      </v-col>

      <v-col cols="12" sm="6" md="4" lg="3">
        <v-menu
          ref="menu"
          v-model="isOpenDate"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="dateRangeText"
              label="Дата"
              prepend-icon="mdi-calendar"
              readonly
              clearable
              v-bind="attrs"
              v-on="on"
              @input="updateDate"
            />
          </template>
          <v-date-picker v-model="dates" range />
        </v-menu>
      </v-col>

      <v-col cols="12" sm="6" md="4" lg="3">
        <v-select
          v-model="duration"
          :items="durationRecommendations"
          label="Срок"
          item-value="value"
          item-text="title"
        />
      </v-col>

      <v-col cols="12" sm="6" md="4" lg="3">
        <v-select v-model="exchange" :items="exchanges" label="Биржа" item-value="uid" item-text="text" />
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import { DateHelper } from "~/core/date";

import { editorFields } from "~/mixins/multi-editor/editor-fields";

export default {
  mixins: [editorFields],
  props: {
    tableParams: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      exchanges: [
        { uid: [], text: "Любая биржа" },
        { uid: "SPBE", text: "СПБ Биржа" },
        { uid: "MISX", text: "Мосбиржа" }
      ],
      durationRecommendations: [
        { value: [], title: "Любой" },
        { value: "less-month", title: "До месяца" },
        { value: "month-or-two", title: "1—2 месяца" },
        { value: "three-to-four-months", title: "3—4 месяца" },
        { value: "five-to-eleven-months", title: "5—11 месяцев" },
        { value: "one-year", title: "Год" }
      ],
      isOpenDate: false
    };
  },
  computed: {
    duration: {
      get() {
        return this.tableParams.duration ?? this.durationRecommendations[0];
      },
      set(value) {
        return this.setField("duration", value);
      }
    },
    exchange: {
      get() {
        return this.tableParams.exchange ?? this.exchanges[0];
      },
      set(value) {
        return this.setField("exchange", value);
      }
    },

    dates: {
      get() {
        if (this.tableParams.date?.till) {
          return [this.tableParams.date?.from, this.tableParams.date.till];
        } else if (this.tableParams.date?.from) {
          return [this.tableParams.date?.from];
        } else {
          return [];
        }
      },
      set(value) {
        if (value.length === 1) {
          return this.setField("date", { from: value[0] });
        } else if (value.length > 1) {
          return this.setField("date", { from: value[0], till: value[1] });
        }
      }
    },

    dateRangeText() {
      return this.dates.map(date => DateHelper.getHumanFriendlyDate(date)).join(" - ");
    }
  },
  methods: {
    updateDate(value) {
      if (!value) {
        this.setField("date", []);
      }
    }
  }
};
</script>
